import { createServiceManifest } from '@estee/elc-service';
import { serviceQueries } from '@estee/elc-service-bus';
import { IManifestServiceRegistryComp } from '@estee/elc-universal-utils';
import { ApptBookingService } from './service-setup/ApptBookingService';

const { name, version } = __serviceInfo__;

const serviceViews = ['elc-appt-booking-cancellation-confirmation', 'appt-booking-landing'];
const serviceEvents: string[] = [];
const queries: string[] = [serviceQueries.GET_APPT_BOOKING_COUNTERS];

export const ServiceManifest: IManifestServiceRegistryComp = createServiceManifest<
    ApptBookingService
>(
    {
        views: serviceViews,
        events: serviceEvents,
        queries,
        name,
        version
    },
    () =>
        import(
            /* webpackChunkName: 'core' */
            './service-setup/diContainer'
        ),
    'apptBookingService'
);
